import { render, staticRenderFns } from "./BannerComp3.vue?vue&type=template&id=5fe5bc11&scoped=true"
import script from "./BannerComp3.vue?vue&type=script&lang=js"
export * from "./BannerComp3.vue?vue&type=script&lang=js"
import style0 from "./BannerComp3.vue?vue&type=style&index=0&id=5fe5bc11&prod&lang=css"
import style1 from "./BannerComp3.vue?vue&type=style&index=1&id=5fe5bc11&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fe5bc11",
  null
  
)

export default component.exports